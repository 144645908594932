import React, { Suspense, lazy, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "./app.scss";
import { Route, Switch, useHistory } from "react-router-dom";
import { sentryUrl, serverStringTs } from "./utils/config";
import ErrorBoundary from "./layouts/ErrorBoundary";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import dumpEvent from "./utils/analytics";
import pjson from "../package.json";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/Home"));

const Identity = lazy(() => import("./pages/Identity"));

const Profiles = lazy(() => import("./pages/Profiles"));

const Profiles2 = lazy(() => import("./pages/Profiles2"));

const Student = lazy(() => import("./pages/Student"));

const Registration = lazy(() => import("./pages/Registration"));

const Registration2 = lazy(() => import("./pages/Registration2"));

const HomeworkWorksheets = lazy(() => import("./pages/HomeworkWorksheets"));

const Homework = lazy(() => import("./pages/Homework"));

const Root = lazy(() => import("./pages/Root"));

const HomeworkReview = lazy(() => import("./pages/HomeworkReview"));

const NotFoundError = lazy(() => import("./pages/Errors/NotFoundError"));

const Downtime = lazy(() => import("./pages/Downtime/Downtime"));

const Profile = lazy(() => import("./pages/Profile"));

const DailyPracticeLanding = lazy(() => import("./pages/DailyPracticeLanding"));

Sentry.init({
    dsn: sentryUrl,
});

function App() {
    const history = useHistory();

    dumpEvent("pwa_version : " + pjson.version, {});

    useEffect(() => {
        axios
            .get(serverStringTs + "/")
            .then((res) => {
                if (!res.data.serverUp) {
                    history.push("/downtime");
                    return;
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 502) {
                    history.push("/downtime");
                    return;
                }
            });
    }, []);

    return (
        <Suspense
            fallback={
                <div className="studentcontainer unselectable">
                    <div className="studentappview studentappviewloader">
                        <div className="studentScreenLoader" />
                    </div>
                </div>
            }
        >
            <ErrorBoundary>
                <Switch>
                    <Route path="/home/:classroomId" component={Home} />
                    <Route path="/identity/:classroomId" component={Identity} />
                    <Route path="/identity" component={Identity} />
                    <Route path="/profile/:studentId" component={Profile} />
                    <Route path="/profiles/:classroomId" component={Profiles} />
                    <Route
                        path="/profiles2/:phoneNumber/:classroomId"
                        component={Profiles2}
                    />
                    <Route
                        path="/profiles2/:phoneNumber"
                        component={Profiles2}
                    />
                    <Route
                        path="/registration/:classroomId"
                        component={Registration}
                    />
                    <Route path="/registration" component={Registration} />
                    <Route
                        path="/registration2/:classroomId"
                        component={Registration2}
                    />
                    <Route path="/registration2" component={Registration2} />
                    <Route path="/student/:studentId" component={Student} />
                    <Route
                        path="/homeworkWorksheets/:homeworkId/:studentId/:studentHomeworkId"
                        component={HomeworkWorksheets}
                    />
                    <Route
                        path="/worksheetHomework/:homeworkId/:studentHomeworkId/:worksheetId/:versionId"
                        component={Homework}
                    />
                    <Route
                        path="/homework/:homeworkId/:studentHomeworkId"
                        component={Homework}
                    />
                    <Route
                        path="/worksheetHomeworkReview/:homeworkId/:studentHomeworkId/:worksheetId/:versionId"
                        component={HomeworkReview}
                    />
                    <Route
                        path="/homeworkReview/:homeworkId/:studentHomeworkId"
                        component={HomeworkReview}
                    />
                    <Route
                        path="/daily-practice/:teacherPhNo"
                        component={DailyPracticeLanding}
                    />
                    <Route path="/root" component={Root} />
                    <Route path="/downtime" component={Downtime} />
                    <Route component={NotFoundError} />
                </Switch>
            </ErrorBoundary>
            <ToastContainer />
        </Suspense>
    );
}

export default App;
