export const ENV = "prod"; // prod
// export const ENV = "test"; // test

export const serverStringTs = "https://homeworkapp.ai/root2/ts"; // prod
// export const serverStringTs = "https://test-api.homeworkapp.ai/test/ts"; // test
// export const serverStringTs = "http://localhost:8000/ts"; // local

export const authString = "https://prod-api.homeworkapp.ai/hermes"; // prod
// export const authString = "https://staging-api.homeworkapp.ai/hermes"; // test
// export const authString = "http://"localhost:8000; // local

export const TRUECALLER_KEY = "Mkmhq217ed221c8ff475ba49e3ee5b22dd609"; //prod
// export const TRUECALLER_KEY = "vWjOg3f03c3eff4a24e14a2ba90034848515b"; //test

export const TRUECALLER_NAME = "homeworkapp"; //prod
// export const TRUECALLER_NAME = "test-homeworkapp"; //test

// export const sentryUrl =
// "https://b6e19b70565a472f8f8079e8e6eade49@o503779.ingest.sentry.io/5589358"; // Test
export const sentryUrl =
    "https://6927cfeceafb4015b0566e9372454c66@o503779.ingest.sentry.io/5589356"; // Prod

export const client_id = "439079ec15f8f2be0a707f44bebcc286";
export const client_secret =
    "0108d497f94c173a4f4b0669c17904f952e9badb19b2fe9cffae862299316007";

export const REGION = "ap-south-1";

// export const V1_IDENTITY_POOL_ID =
// "ap-south-1:e2a4ec6f-6dad-47bf-883a-585f22f35083"; // test
export const V1_IDENTITY_POOL_ID =
    "ap-south-1:e2a4ec6f-6dad-47bf-883a-585f22f35083"; // prod

// export const V2_IDENTITY_POOL_ID =
// "ap-south-1:7c07eb58-aaa3-4d39-acb2-e9909d39e57b"; // test
export const V2_IDENTITY_POOL_ID =
    "ap-south-1:19af025a-197a-4171-9cda-792c5e46f027"; // prod

// export const V2_HOMEWORK_SUBMISSIONS_BUCKET = "test-homework-submissions"; // test
export const V2_HOMEWORK_SUBMISSIONS_BUCKET = "homework-submissions"; // prod

// export const V2_CUSTOM_HOMEWORK_SUBMISSIONS_BUCKET =
// "test-custom-homework-submissions"; // test
export const V2_CUSTOM_HOMEWORK_SUBMISSIONS_BUCKET =
    "custom-homework-submissions"; // prod

export const default_student_id = "2342342341";
